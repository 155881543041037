import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// ----------------------------------------------------------------------
function userCheck({ ...other }) {
    const theme = useTheme();
    return (_jsx(Box, { ...other, children: _jsxs("svg", { width: "65", height: "64", viewBox: "0 0 65 64", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("rect", { x: "0.5", width: "64", height: "64", rx: "32", fill: "#B4B9C9" }), _jsxs("g", { "clip-path": "url(#clip0_587_89761)", children: [_jsx("path", { d: "M36.5 41V39C36.5 37.9391 36.0786 36.9217 35.3284 36.1716C34.5783 35.4214 33.5609 35 32.5 35H25.5C24.4391 35 23.4217 35.4214 22.6716 36.1716C21.9214 36.9217 21.5 37.9391 21.5 39V41", stroke: "white", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }), _jsx("path", { d: "M37.5 31L39.5 33L43.5 29", stroke: "white", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" }), _jsx("path", { d: "M29 31C31.2091 31 33 29.2091 33 27C33 24.7909 31.2091 23 29 23C26.7909 23 25 24.7909 25 27C25 29.2091 26.7909 31 29 31Z", stroke: "white", "stroke-width": "2", "stroke-linecap": "round", "stroke-linejoin": "round" })] }), _jsx("defs", { children: _jsx("clipPath", { id: "clip0_587_89761", children: _jsx("rect", { width: "24", height: "24", fill: "white", transform: "translate(20.5 20)" }) }) })] }) }));
}
export default memo(userCheck);
